import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getGiftItemsAction } from "../Action/action";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import moment from "moment";
import config from "../coreFIles/config";

const GiftItems = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    getGiftItems();
  }, []);

  const getGiftItems = async () => {
    setIsLoading(true);
    try {
      let res = await getGiftItemsAction();
      if (res.success) {
        setData(res.data);
      }
      setIsError(false);
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    setIsLoading(false);
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "item.name",
        header: "Nome Prodotto",
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: "item.image",
        header: "Immagine",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <img 
            src={config.imageUrl + row.original.item.image} 
            alt={row.original.item.name} 
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
          />
        ),
      },
      {
        accessorKey: "previous_owner",
        header: "Proprietario Precedente",
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: (row) => `${row.previous_owner.country_code} ${row.previous_owner.mobile_no}`,
        Cell: ({ row }) => (
          <a 
            href={`${config.baseUrl}userDetails?id=${row.original.previous_owner.id}`}
            className="text-primary"
            style={{ textDecoration: "underline" }}
          >
            {row.original.previous_owner.country_code} {row.original.previous_owner.mobile_no} <br />
            {row.original.previous_owner.email}
          </a>
        ),
      },
      {
        accessorKey: "actual_owner",
        header: "Proprietario Attuale",
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: (row) => `${row.actual_owner.country_code} ${row.actual_owner.mobile_no}`,
        Cell: ({ row }) => (
          <a 
            href={`${config.baseUrl}userDetails?id=${row.original.actual_owner.id}`}
            className="text-primary"
            style={{ textDecoration: "underline" }}
          >
            {row.original.actual_owner.country_code} {row.original.actual_owner.mobile_no} <br />
            {row.original.actual_owner.email}
          </a>
        ),
      },
      {
        accessorKey: "amount",
        header: "Quantità",
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: "status",
        header: "Status",
        enableSorting: true,
        enableColumnFilter: true,
        filterVariant: 'select',
        filterSelectOptions: ['pending', 'success'],
        Cell: ({ row }) => {
          switch (row.original.status) {
            case "pending":
              return <span className="badge badge-warning">In attesa</span>;
            case "success":
              return <span className="badge badge-success">Completato</span>;
            default:
              return <span className="badge badge-warning">In attesa</span>;
          }
        },
      },
      {
        accessorKey: "createdAt",
        header: "Data Creazione",
        Cell: ({ row }) => moment(row.original.createdAt).format("DD-MM-YYYY HH:mm:ss"),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: true,
    enableColumnFilters: true,
    enablePagination: true,
    enableSorting: true,
    enableGlobalFilter: true,
    enableFilters: true,
    initialState: { showColumnFilters: true },
    muiTableBodyRowProps: { hover: false },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Regalo Beni</h4>
                    </div>
                    <div className="box-body">
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default GiftItems; 