import React, { useEffect, useState, useMemo } from "react";
// import config from '../coreFIles/config'
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import { deleteItemAction, getItemListAction, updateItemOnboardingAction, updateItemFractionalizeAction, refundItemAction, liquidateItem } from "../Action/action";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
// import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";

const Items = () => {
  const [getItemlist, setItemList] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [form] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState({});
  const [saving, setSaving] = useState(false);
  const [sellPrice, setSellPrice] = useState(0);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    let res = await getItemListAction();
    if (res.success) {
      setItemList(res.data);
      setAllItems(res.data);
    }
  };

  const processingAlert = async () => {
    let timerInterval;
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: "<b>Transaction is being process...</b></br><p>Please do not close the window</p>",
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Transaction done!");
      }
    });
  };

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const sellItem = async (e) => {
    e.preventDefault();
    try {
      console.log(item.id);
      setError(false);
      setSaving(true);

      await liquidateItem(item.id, { amount: sellPrice });
      Swal.fire({
        title: "Item sold!",
        text: "Item sold successfully!",
        icon: "success",
      });
    } catch (error) {
      setError(true);
      Swal.fire({
        title: "Error!",
        text: "Item sold failed!",
        icon: "error",
      });
    }
    setSaving(false);
    setShowModal(false);
    setItem({});
    setSellPrice(0);

    getItems();
  };

  const onboardItem = async (item) => {
    processingAlert();
    try {
      let res = await updateItemOnboardingAction({ item_id: item.id });
      console.log(res);
      // Simulate a delay of 10 seconds
      await new Promise((resolve) => setTimeout(resolve, 20000));
      toast.success("Item onboarded successfully");
    } catch (err) {
      toast.error("Transaction reverted: " + err.toString().split("execution reverted:")[1]?.toString().split('"')[0]);
      return false;
    } finally {
      closeProcessingAlert();
      // Reload the page after onboarding
      getItems();
    }
  };

  const downloadCsv = async () => {
    const response = await getItemListAction();
    console.log(response);
    if (response.success) {
      const csvData = [
        ["ID", "Category", "Name", "Share Quantity", "Share Price", "Market Value", "Purchase Price", "Purchase Source", "Market Price","Expiration","Creation Date","Label"],
        ...response.data.map((item) => [
          item.id,
          item.category.category_name,
          `"${item.name}"`,
          item.share_quantity,
          `"${item.share_price?.toString().replace(".", ",")}"`,
          `"${item.market_value?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${item.purchase_price?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${item.purchase_source ?? "N/A"}"`,
          `"${item.market_price ? Math.round(item.market_price).toString() : "N/A"}"`,
          `"${item.tagCustom ?? "N/A"}"`,
          item.created_at,
          `"${item.lable ?? "N/A"}"`,
        ]),
      ];

      const csvString = csvData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "items_list.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.error("Failed to retrieve daily transactions");
    }
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 50, //small column
      },
      {
        accessorKey: "category",
        header: "Category",
        accessorFn: (row) => row.category.category_name,
        size: 100, //medium column
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "image",
        header: "Image",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <a target="__blank" href={`${config.imageUrl}${row.original.image}`}>
              <img src={`${config.imageUrl}${row.original.image}`} width="70px" height="70px" alt="" />
            </a>
          </>
        ),
        size: 100, //medium column
      },
      {
        accessorKey: "details",
        header: "Details",
        Cell: ({ row }) => (
          <>
            <b>Total Share : </b>
            {row.original.share_quantity}
            <br />
            <b>Price : </b>€ {row.original.share_price}/share
            <br />
            <b>Last Year Perf : </b>
            {row.original.last_year_perf}%<br />
            <b>Market Value : </b>€ {row.original.market_value}
            <br />
            <b>Last Month Price : </b>€ {row.original.last_month_price}
          </>
        ),
      },
      {
        accessorKey: "start_price",
        header: "Initial Price",
        Cell: ({ row }) => `${row.original.share_price * row.original.share_quantity}€`,
        size: 50, //medium column
      },
      {
        accessorKey: "market_price",
        header: "Market Price",
        Cell: ({ row }) => `${Math.round(row.original.market_price)}€`,
        size: 100, //medium column
      },
      {
        accessorKey: "tagCustom",
        header: "Expiration Date",
        Cell: ({ row }) => `${row.original.tagCustom}`,
        size: 100, //medium column
      },
      {
        accessorKey: "created_at",
        header: "Creation date",
        Cell: ({ row }) => `${row.original.created_at}`,
        size: 100, //medium column
      },
      {
        accessorKey: "supplier",
        header: "Supplier",
        size: 100, //medium column
        Cell: ({ row }) =>
          row.original.supplier ? (
            <a href={`${config.baseUrl}fornitori/${row.original.supplier.id}`} className="text-primary" style={{ textDecoration: "underline", cursor: "pointer" }}>
              {row.original.supplier.name}
            </a>
          ) : (
            "-"
          ),
      },
      {
        accessorKey: "warehouse",
        header: "Warehouse",
        size: 100, //medium column
        Cell: ({ row }) =>
          row.original.warehouse ? (
            <a href={`${config.baseUrl}magazzini/${row.original.warehouse.id}`} className="text-primary" style={{ textDecoration: "underline", cursor: "pointer" }}>
              {row.original.warehouse.name}
            </a>
          ) : (
            "-"
          ),
      },
      {
        accessorKey: "draft",
        header: "Stato",
        size: 100,
        Cell: ({ row }) => <span className={`badge ${row.original.draft ? "bg-warning" : "bg-success"}`}>{row.original.draft ? "Bozza" : "Pubblicato"}</span>,
      },
      {
        header: "Onboard",
        size: 100, //medium column
        Cell: ({ row }) => (
          <>
            {parseInt(row.original.collected_share) === parseInt(row.original.share_quantity) && row.original.onboarded === false && row.original.fractionalize === false ? (
              <button type="button" className="btn btn-sm btn-default" id="editbtnid" onClick={() => onboardItem(row.original)}>
                Onboard
              </button>
            ) : (
              ""
            )}
            {row.original.onboarded === true && row.original.fractionalize === false ? "Fractionalizing..." : ""}
            {row.original.onboarded === true && row.original.fractionalize === true ? (
              <a target="__blank" href={`${config.blockchainUrl}tx/${row.original.fractionalizeHash}`}>
                <i className="fa fa-check"> Onboarded & Fractionalized</i>
              </a>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        accessorKey: "sell",
        header: "Sell",
        size: 100, //medium column
        Cell: ({ row }) => (
          <>
            {row.original.item_put_on === 3 && <span className="badge bg-success">Sold</span>}
            {row.original.onboarded === true && row.original.fractionalize === true && row.original.item_put_on !== 3 ? (
              <button
                type="button"
                className="btn btn-sm btn-default"
                id="editbtnid"
                onClick={() => {
                  setItem(row.original);
                  setShowModal(true);
                }}
              >
                Sell item
              </button>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 100, //medium column
        Cell: ({ row }) => (
          <>
            <a href={`${config.baseUrl}itemAdd/${row.original.id}`} className="btn btn-primary btn-sm">
              Modifica
            </a>
            <br />
            <br />
            {!row.original.onboarded && !row.original.draft && (
              <button type="button" className="btn btn-sm btn-default" id="editbtnid" onClick={() => refundItem(row.original.id)}>
                Refund
              </button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: getItemlist,
    enableColumnActions: false,
    enableColumnFilters: true,
    enablePagination: true,
    enableSorting: true,
    muiTableBodyRowProps: { hover: false },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      isLoading,
    },
  });

  const deleteItem = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteItemAction({ item_id: id });
        if (res.success) {
          getItems();
          // toast.success(res.msg);
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };

  const refundItem = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to refund this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Refund it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await refundItemAction({ item_id: id });
        if (res.success) {
          getItems();
          // toast.success(res.msg);
          Swal.fire("Refunded!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };

  const handleActionChange = (value) => {
    switch (value) {
      case "primary":
        setItemList(allItems.filter((item) => item.onboarded === 0 && item.fractionalize === 0));
        break;
      case "secondary":
        setItemList(allItems.filter((item) => item.onboarded === 1 && item.fractionalize === 1));
        break;
      case "all":
      default:
        getItems();
        break;
    }
  };

  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <div className="wrapper">
          <Header />
          <Toaster />
          <Sidebar />
          <div className="content-wrapper">
            <div className="container-full">
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="box">
                      <div className="box-header with-border">
                        <h4 className="box-title">Items List</h4>
                        <button type="button" className="btn btn-sm btn-primary add_btn" onClick={downloadCsv}>
                          Download CSV
                        </button>
                        <a href={`${config.baseUrl}itemAdd`} className="btn btn-sm btn-primary add_btn">
                          Add New
                        </a>
                      </div>
                      <div className="box-body">
                        <MaterialReactTable table={table} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="modal " id="liquidityModal" aria-labelledby="liquidityModalLabel" style={{ display: showModal ? "block" : "none" }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  {/* await items is not empty */}
                  {Object.keys(item).length > 0 ? (
                    <>
                      <div className="modal-header" data-bs-theme="dark">
                        <h5 className="modal-title" id="liquidityModalLabel">
                          Sell item
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setShowModal(false);
                            setError(false);
                          }}
                        />
                      </div>
                      {error ? (
                        <div className="modal-body">
                          <div className="container">
                            <div className="mb-3">
                              <p className="text-center">Something went wrong. Please try again later</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={(e) => sellItem(e)}>
                          <div className="modal-body">
                            <div className="container">
                              <div className="mb-4 d-flex align-items-center">
                                {/** Liquidity Details some p whit some data */}
                                <img src={`${config.imageUrl}` + item.image} width="70px" height="70px" alt=""></img>
                                <div className="p-3">
                                  <p>
                                    <strong>Item:</strong> {item.name}
                                  </p>
                                  <p>
                                    <strong>Category:</strong> {item.category_name}
                                  </p>
                                  <p>
                                    <strong>Shares:</strong> {item.share_quantity}
                                  </p>
                                  <p>
                                    <strong>Price per share:</strong> {sellPrice / item.share_quantity}
                                  </p>
                                </div>
                              </div>

                              <div className="mt-4 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Sell price (€)
                                </label>
                                <input
                                  type="number"
                                  step=".01"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter sell price"
                                  onChange={(e) => setSellPrice(e.target.value)}
                                  name="sell_price"
                                />
                                {/*                                   {validatioError.lqiAmt && <span className="validationErr mt-1">Liquidity amount error</span>}
                                 */}
                              </div>

                              <div className="modal-footer mt-20">
                                {!saving ? (
                                  <button type="submit" className="btn btn-primary">
                                    Set sell price
                                  </button>
                                ) : (
                                  <button type="submit" className="btn btn-primary" disabled>
                                    Please wait <i className="fa fa-spinner fa-spin"></i>
                                  </button>
                                )}

                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowModal(false);
                                    setError(false);
                                  }}
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </>
                  ) : (
                    <>
                      
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*Sell item Modal Ends */}
            </div>
          </div>

          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};
export default Items;
